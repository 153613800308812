@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  font-family: "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: rgb(78, 78, 78);
}

.DayPicker {
  font-size: 1.5rem;
}

.app {
  display: grid;
  place-items: center;
  height: 100vh;
}

.hide {
  display: none;
}

.error__Message {
  color: red;
  margin-bottom: 10px;
  margin-top: -10px;
}

.app__Email {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.app__EmailContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 30px;
  border: 1px solid rgba(177, 177, 177, 0.486);
  /* min-width: 400px; */
  border-radius: 10px;
}
.app__AppointmentName {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 30px;
}

.app__AppointmentNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding: 30px 30px; */
  border: 1px solid rgba(177, 177, 177, 0.486);
  /* min-width: 400px; */
  border-radius: 10px;
}
.appName__input {
  padding: 10px 10px;
  border: 1px solid rgba(177, 177, 177, 0.486);
  outline: none;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 100%;
  font-size: large;
}

.appDetails__input {
  padding: 10px 10px;
  border: 1px solid rgba(177, 177, 177, 0.486);
  outline: none;
  border-radius: 10px;
  margin-bottom: 20px;
  /* width: 100%; */
  font-size: large;
  min-height: 100px;
}

.app__appName_Container__Top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e6f1ff;
  margin-bottom: 5px;
  color: black;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.app__appName_Container__TopText {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 0px 10px;
  /* background-color: #E6F1FF; */
  color: black;
  font-size: larger;
}

.app__appName_Container__TopText > p {
  color: grey;
  font-size: medium;
}
.app__appName_Container__Bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  /* background-color: #E6F1FF; */
  color: black;
  font-size: larger;
}
.progress__Bar {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.progress__Bar__rect {
  margin-right: 10px;
  width: 40px;
  height: 8px;
  border-radius: 5px;
  background-color: rgb(196, 196, 196);
}

.calendar__Icon {
  width: 80px !important;
}

.email__Input {
  padding: 10px 10px;
  border: 1px solid rgba(177, 177, 177, 0.486);
  outline: none;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 100%;
  font-size: large;
}

.app__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.next__button {
  width: 100px;
  border: none;
  outline: none;
  padding: 10px 10px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
  background-color: #04a1ff;
  color: white;
  font-weight: 400;
  font-size: medium;
  /* left: 85%; */
}

.next__button:hover {
  background-color: #0082ce;
  transition: 200ms ease-in;
}

.app__DayPicker {
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px;
}

.app__DayPicker__Container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  border: 1px solid rgba(177, 177, 177, 0.486);
  border-radius: 10px;
}

.app__DayPicker_Container__Top {
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e6f1ff;
  margin-bottom: 5px;
  color: black;
}

.app__DayPicker_Container__TopText {
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 0px 10px;
  /* background-color: #E6F1FF; */
  color: black;
  font-size: larger;
}

.app__DayPicker_Container__TopText > p {
  color: grey;
  font-size: medium;
}

.app__DayPicker__days {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.app__DayPicker__days__button {
  padding: 10px 10px;
  margin-right: 10px !important;
  margin-left: 10px !important;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: medium;
  cursor: pointer;
  background-color: #eeefef;
}

.app__DayPicker__days__appointment {
  margin-left: -20px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin-bottom: 10px;
}

.app__DayPicker__days__button__appointmen {
  padding: 10px 10px;
  /* margin-right: 10px !important; */
  /* margin-left: 10px !important; */
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: medium;
  cursor: pointer;
  background-color: #eeefef;
  margin-left: 20px;
}

.hoverColor {
  background-color: #55a9da !important;
  color: white;
}

.app__DayPicker_Bottom {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  /* align-items: center; */
}
.app__TimePicker__Container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.app__TimePicker__Container__timeInput {
  padding: 10px 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  background-color: #eeefef;
  font-size: 1rem;
}

.app__googleMessage {
  font-size: larger;
  padding: 20px 20px;
}

.fadeOut {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

@media only screen and (max-width: 600px) {
  .app__DayPicker__days {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .app__DayPicker__days__button {
    width: 350px;
    margin-bottom: 10px;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .app__googleMessage {
    font-size: small;
  }
  .next__button {
    left: 65%;
  }
}

